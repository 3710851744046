<template>
    <div class="modal-mask choose-language-modal">
        <div id="el-wrapper" class="grid-container grid-parent">
            <div>
                <button class="closeButton"
                        @click="close">
                    x
                </button>
            </div>
            <div id="logo" class="row">
                <div class="col-md-12 col-sm-12 col-lg-12 wrapper-title">
                    <img src="/assets/png/logo-goodyear-otr-sm.png" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12 wrapper-title">
                    <label>{{title}}</label>
                </div>
            </div>
            <div class="row d-none d-md-flex">
                <div class="col-md-3 region-col na"
                     @mouseleave="mouseLeaveImg(na)"
                     @mouseover="mouseOverImg('na')">
                    <div id="na-map" class="map"
                         @mouseover="mouseOverImg('na')"
                         :style="{opacity: na.isHovered ? '1' : '.25'}">

                    </div>
                    <div class="map-name"
                         :style="{color: na.isHovered ? '#ffffff' : '#848484'}">
                        <span>{{na.label}}</span>
                    </div>
                    <div class="map-lang">
                        <div v-for="lang in na.langs"
                             :key="lang.locale">
                            <span class="span-lang"
                                  :style="{opacity: lang.isHovered ? '1' : '.5'}"
                                  @mouseover="mouseOverLang(lang)"
                                  @click="pickLang(lang)">
                                {{lang.label}}
                            </span>
                        </div>

                    </div>
                </div>
                <div class="col-md-3 region-col col-sm-6 sa"
                     @mouseleave="mouseLeaveImg(sa)"
                     @mouseover="mouseOverImg('sa')">
                    <div id="sa-map" class="map"
                         @mouseover="mouseOverImg('sa')"
                         :style="{opacity: sa.isHovered ? '1' : '.25'}">

                    </div>
                    <div class="map-name"
                         :style="{color: sa.isHovered ? '#ffffff' : '#848484'}">
                        <span>
                            {{sa.label}}
                        </span>
                    </div>
                    <div class="map-lang">
                        <div v-for="lang in sa.langs"
                             :key="lang.locale">
                            <span class="span-lang"
                                  :style="{opacity: lang.isHovered ? '1' : '.5'}"
                                  @mouseover="mouseOverLang(lang)"
                                  @click="pickLang(lang)">
                                {{lang.label}}
                            </span>
                        </div>

                    </div>
                </div>

                <div class="col-md-4 col-sm-6 region-col eu"
                     @mouseleave="mouseLeaveImg(eu)"
                     @mouseover="mouseOverImg('eu')">
                    <!-- Image  -->
                    <div id="eu-map" class="map"
                         @mouseover="mouseOverImg('eu')"
                         :style="{opacity: eu.isHovered ? '1' : '.25'}">
                    </div>
                    <!-- Label  -->
                    <div class="map-name"
                         :style="{color: eu.isHovered ? '#ffffff' : '#848484'}">
                        <span>{{eu.label}}</span>
                    </div>
                    <!-- Lang list  -->
                    <div class="map-lang">
                        <ul class="ul-lang p-0">
                            <li v-for="lang in eu.langs"
                                :key="lang.locale">
                                <span class="span-lang"
                                      :style="{opacity: lang.isHovered ? '1' : '.5'}"
                                      @mouseover="mouseOverLang(lang)"
                                      @click="pickLang(lang)">
                                    {{lang.label}}
                                </span>
                            </li>

                        </ul>

                    </div>
                </div>
                <div class="col-md-2 col-sm-6 region-col as"
                     @mouseleave="mouseLeaveImg(as)"
                     @mouseover="mouseOverImg('as')">
                    <div id="as-map" class="map"
                         @mouseover="mouseOverImg('as')"
                         :style="{opacity: as.isHovered ? '1' : '.25'}">

                    </div>
                    <div class="map-name"
                         :style="{color: as.isHovered ? '#ffffff' : '#848484'}">
                        <span>{{as.label}}</span>
                    </div>
                    <div class="map-lang">
                        <div v-for="lang in as.langs"
                             :key="lang.locale">
                            <span class="span-lang"
                                  :style="{opacity: lang.isHovered ? '1' : '.5'}"
                                  @mouseover="mouseOverLang(lang)"
                                  @click="pickLang(lang)">
                                {{lang.label}}
                            </span>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row d-flex d-md-none">
                <div class="col-md-12 col-sm-12 col-lg-12 text-center">
                    <div class="select-map">
                        <b-form-select v-model="selectedMap"
                                       :options="mapOptions"
                                       class="form-control input-sm"
                                       @input="selectMap($event)"></b-form-select>
                    </div>
                </div>
            </div>
            <div class="row d-flex d-md-none">
                <div class="col-12 text-center">
                    <div class="select-map">
                        <b-form-select v-model="selectedLang"
                                       :options="langOptions"
                                       class="form-control input-sm"
                                       @input="selectLang($event)"></b-form-select>
                    </div>
                </div>
            </div>
            <div class="row d-flex d-md-none">
                <div class="col-12 text-center">
                    <a role="button" class="btn gy-btn continue-btn" @click="continueChooseLang">{{ continueLabel }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// styling
import 'vue-select/dist/vue-select.css';
// api
import { mapGetters, mapActions } from "vuex";
import { API_TRANSLATIONS_ACTION_LOAD } from "@/core/services/api/translations.module";

import '@/scripts/ieEvent.module.js';

export default {
    mounted() {
        this.na.langs.forEach(element => {
            this.langOptions.push({
                value: element,
                text: element.label.toUpperCase()
            })
        });
        this.selectedLang = this.langOptions[0].value;
    },
    data() {
        return {
            
            title: 'Please select your language',
            continueLabel: 'Continue',
            selectedMap: {id: 1, label: 'North America'},
            selectedLang: null,
            langOptions: [],
            mapOptions: [
                {value: {id: 1, label: 'North America'}, text: 'NORTH AMERICA'},
                {value: {id: 2, label: 'South America'}, text: 'SOUTH AMERICA'},
                {value: {id: 3, label: 'Europe'}, text: 'EUROPE'},
                {value: {id: 4, label: 'Asia'}, text: 'ASIA'},
            ],
            na: {
                label: 'North America',
                isHovered: false,
                langs: [
                    {
                        locale: 'en-US',
                        label: 'English',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_NA
                    },
                    {
                        locale: 'es-US',
                        label: 'Español',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_NA
                    },
                    {
                        locale: 'en-CA',
                        label: 'English(CA)',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_NA
                    },
                    {
                        locale: 'fr-CA',
                        label: 'Français',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_NA
                    },
                ]
            },
            sa: {
                label: 'South America',
                isHovered: false,
                langs: [
                    {
                        locale: 'es-AR',
                        label: 'Español',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'pt-BR',
                        label: 'Português',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    
                ]
            },
            eu: {
                label: 'Europe',
                isHovered: false,
                langs: [
                    
                    {
                        locale: 'en-GB',
                        label: 'English (UK)',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'es-ES',
                        label: 'Español',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'fr-FR',
                        label: 'Français',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'de-DE',
                        label: 'Deutsch',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'pt-PT',
                        label: 'Português',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'it-IT',
                        label: 'Italiano',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },

                    {
                        locale: 'da-DK',
                        label: 'Danish',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'nl-NL',
                        label: 'Nederlands',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'bg-BG',
                        label: 'Български',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'cs-CZ',
                        label: 'Čeština',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'et-EE',
                        label: 'Eesti keel',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },

                    {
                        locale: 'fi-FI',
                        label: 'Suomi',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'el-GR',
                        label: 'Ελληνικά',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'hu-HU',
                        label: 'Hungarian',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'lv-LV',
                        label: 'Latvių',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'lt-LT',
                        label: 'Lietuviškai',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'no-NO',
                        label: 'Norsk',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'pl-PL',
                        label: 'Polski',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'ro-RO',
                        label: 'Romanian',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'ru-RU',
                        label: 'Русский',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'sr-RS',
                        label: 'Srpski',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'sl-SI',
                        label: 'Slovenščina',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'sv-SE',
                        label: 'Svenska',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'uk-UA',
                        label: 'Українська',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'sk-SK',
                        label: 'Slovenčina',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    }
                ]
            },
            as: {
                label: 'Asia',
                isHovered: false,
                langs: [
                    
                    {
                        locale: 'zh-CN',
                        label: '简体中文',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'ja-JP',
                        label: '日本語',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'id-ID',
                        label: 'Indonesia',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    {
                        locale: 'hi-IN',
                        label: 'हिंदी',
                        isHovered: false,
                        url: process.env.VUE_APP_API_SERVER_HOST_GLOBAL
                    },
                    
                ]
            },
            translations: {
                title: [
                    {locale: 'en-US', translation: 'Please select your language.'},
                    {locale: 'es-US', translation: 'Seleccione su idioma.'},
                    {locale: 'en-CA', translation: 'Please select your language.'},
                    {locale: 'fr-CA', translation: 'Veuillez sélectionner votre langue.'},
                    //
                    {locale: 'es-AR', translation: 'Seleccione su idioma.'},
                    {locale: 'pt-BR', translation: 'Por favor, selecione o seu idioma.'},
                    //
                    {locale: 'en-GB', translation: 'Please select your language.'},
                    {locale: 'es-ES', translation: 'Seleccione su idioma.'},
                    {locale: 'fr-FR', translation: 'Veuillez sélectionner votre langue.'},
                    {locale: 'de-DE', translation: 'Bitte wählen Sie Ihre Sprache aus.'},
                    {locale: 'pt-PT', translation: 'Por favor, selecione o seu idioma.'},
                    {locale: 'it-IT', translation: 'Seleziona una lingua.'},
                    {locale: 'da-DK', translation: 'Vælg dit sprog.'},
                    {locale: 'nl-NL', translation: 'Selecteer uw taal.'},
                    {locale: 'bg-BG', translation: 'Моля, изберете Вашия език.'},
                    {locale: 'cs-CZ', translation: 'Zvolte prosím vámi preferovaný jazyk.'},
                    {locale: 'et-EE', translation: 'Palun valige oma keel.'},
                    {locale: 'fi-FI', translation: 'Valitse kielesi'},
                    {locale: 'el-GR', translation: 'Παρακαλούμε επιλέξτε τη γλώσσα σας.'},
                    {locale: 'hu-HU', translation: 'Kérjük, szíveskedjen az Ön nyelvét kiválasztani.'},
                    {locale: 'lv-LV', translation: 'Pasirinkite savo kalbą.'},
                    {locale: 'lt-LT', translation: 'Pasirinkite savo kalbą.'},
                    {locale: 'no-NO', translation: 'Vennligst velg språket ditt.'},
                    {locale: 'pl-PL', translation: 'Prosimy wybrać język.'},
                    {locale: 'ro-RO', translation: 'Alegeţi limba dvs.'},
                    {locale: 'ru-RU', translation: 'Пожалуйста, выберите Ваш язык'},
                    {locale: 'sr-RS', translation: 'Molimo da izaberete Vaš jezik.'},
                    {locale: 'sl-SI', translation: 'Prosimo, izberite svoj jezik.'},
                    {locale: 'sv-SE', translation: 'Vänligen välj ditt språk.'},
                    {locale: 'uk-UA', translation: 'Будь ласка, виберіть свою мову.'},
                    {locale: 'sk-SK', translation: 'Vyberte si, prosím, preferovaný jazyk.'},
                    //
                    {locale: 'zh-CN', translation: '请选择您的语言。'},
                    {locale: 'ja-JP', translation: '言語を選択してください。'},
                    {locale: 'id-ID', translation: 'Silakan memilih bahasa Anda.'},
                    {locale: 'hi-IN', translation: 'कृपया अपनी भाषा का चयन करें।'}
                ],
                na_title: [
                    {locale: 'en-US', translation: 'North America', url: ''},
                    {locale: 'es-US', translation: 'Norteamérica'},
                    {locale: 'en-CA', translation: 'North America'},
                    {locale: 'fr-CA', translation: 'Amérique du Nord'},
                    //
                    {locale: 'es-AR', translation: 'Norteamérica'},
                    {locale: 'pt-BR', translation: 'América do Norte'},
                    //
                    {locale: 'en-GB', translation: 'North America'},
                    {locale: 'es-ES', translation: 'Norteamérica'},
                    {locale: 'fr-FR', translation: 'Amérique du Nord'},
                    {locale: 'de-DE', translation: 'Nordamerika'},
                    {locale: 'pt-PT', translation: 'América do Norte'},
                    {locale: 'it-IT', translation: 'Nord America'},
                    {locale: 'da-DK', translation: 'Nordamerika'},
                    {locale: 'nl-NL', translation: 'Noord Amerika'},
                    {locale: 'bg-BG', translation: 'Северна Америка'},
                    {locale: 'cs-CZ', translation: 'Severní Amerika'},
                    {locale: 'et-EE', translation: 'Põhja-Ameerika'},
                    {locale: 'fi-FI', translation: 'Pohjois-Amerikka'},
                    {locale: 'el-GR', translation: 'Βόρεια Αμερική'},
                    {locale: 'hu-HU', translation: 'Észak Amerika'},
                    {locale: 'lv-LV', translation: 'Ziemeļamerika'},
                    {locale: 'lt-LT', translation: 'Šiaurės Amerika'},
                    {locale: 'no-NO', translation: 'Nord Amerika'},
                    {locale: 'pl-PL', translation: 'Ameryka północna'},
                    {locale: 'ro-RO', translation: 'America de Nord'},
                    {locale: 'ru-RU', translation: 'Северная Америка'},
                    {locale: 'sr-RS', translation: 'Северна Америка'},
                    {locale: 'sl-SI', translation: 'Severna Amerika'},
                    {locale: 'sv-SE', translation: 'Nordamerika'},
                    {locale: 'uk-UA', translation: 'Північна Америка'},
                    {locale: 'sk-SK', translation: 'Severná Amerika'},
                    //
                    {locale: 'zh-CN', translation: '北美'},
                    {locale: 'ja-JP', translation: '北米'},
                    {locale: 'id-ID', translation: 'Amerika Utara'},
                    {locale: 'hi-IN', translation: 'उत्तरी अमेरिका'}
                    
                ],
                sa_title: [
                    {locale: 'en-US', translation: 'South America'},
                    {locale: 'es-US', translation: 'Sudamerica'},
                    {locale: 'en-CA', translation: 'South America'},
                    {locale: 'fr-CA', translation: 'Amérique du sud'},
                    //
                    {locale: 'es-AR', translation: 'Sudamerica'},
                    {locale: 'pt-BR', translation: 'América do Sul'},
                    //
                    {locale: 'en-GB', translation: 'South America'},
                    {locale: 'es-ES', translation: 'Sudamerica'},
                    {locale: 'fr-FR', translation: 'Amérique du sud'},
                    {locale: 'de-DE', translation: 'Südamerika'},
                    {locale: 'pt-PT', translation: 'América do Sul'},
                    {locale: 'it-IT', translation: 'Sud America'},
                    {locale: 'da-DK', translation: 'Sydamerika'},
                    {locale: 'nl-NL', translation: 'Zuid-Amerika'},
                    {locale: 'bg-BG', translation: 'Южна Америка'},
                    {locale: 'cs-CZ', translation: 'Jižní Amerika'},
                    {locale: 'et-EE', translation: 'Lõuna-Ameerika'},
                    {locale: 'fi-FI', translation: 'Etelä-Amerikka'},
                    {locale: 'el-GR', translation: 'νότια Αμερική'},
                    {locale: 'hu-HU', translation: 'Dél Amerika'},
                    {locale: 'lv-LV', translation: 'Dienvidamerika'},
                    {locale: 'lt-LT', translation: 'Pietų Amerika'},
                    {locale: 'no-NO', translation: 'Sør Amerika'},
                    {locale: 'pl-PL', translation: 'Ameryka Południowa'},
                    {locale: 'ro-RO', translation: 'America de Sud'},
                    {locale: 'ru-RU', translation: 'Южная Америка'},
                    {locale: 'sr-RS', translation: 'Јужна Америка'},
                    {locale: 'sl-SI', translation: 'Južna Amerika'},
                    {locale: 'sv-SE', translation: 'Sydamerika'},
                    {locale: 'uk-UA', translation: 'Південна Америка'},
                    {locale: 'sk-SK', translation: 'Južná Amerika'},
                    //
                    {locale: 'zh-CN', translation: '南美洲'},
                    {locale: 'ja-JP', translation: '南アメリカ'},
                    {locale: 'id-ID', translation: 'Amerika Selatan'},
                    {locale: 'hi-IN', translation: 'दक्षिण अमेरिका'}
                ],
                eu_title: [
                    {locale: 'en-US', translation: 'Europe'},
                    {locale: 'es-US', translation: 'Europa'},
                    {locale: 'en-CA', translation: 'Europe'},
                    {locale: 'fr-CA', translation: `L'Europe`},
                    //
                    {locale: 'es-AR', translation: 'Europa'},
                    {locale: 'pt-BR', translation: 'Europa'},
                    //
                    {locale: 'en-GB', translation: 'Europe'},
                    {locale: 'es-ES', translation: 'Europa'},
                    {locale: 'fr-FR', translation: `L'Europe`},
                    {locale: 'de-DE', translation: 'Europa'},
                    {locale: 'pt-PT', translation: 'Evropa'},
                    {locale: 'it-IT', translation: 'Europa'},
                    {locale: 'da-DK', translation: 'Europa'},
                    {locale: 'nl-NL', translation: 'Europa'},
                    {locale: 'bg-BG', translation: 'Европа'},
                    {locale: 'cs-CZ', translation: 'Evropa'},
                    {locale: 'et-EE', translation: 'Euroopa'},
                    {locale: 'fi-FI', translation: 'Eurooppa'},
                    {locale: 'el-GR', translation: 'Ευρώπη'},
                    {locale: 'hu-HU', translation: 'Európa'},
                    {locale: 'lv-LV', translation: 'Eiropa'},
                    {locale: 'lt-LT', translation: 'Europa'},
                    {locale: 'no-NO', translation: 'Europa'},
                    {locale: 'pl-PL', translation: 'Europa'},
                    {locale: 'ro-RO', translation: 'Europa'},
                    {locale: 'ru-RU', translation: 'Европа'},
                    {locale: 'sr-RS', translation: 'Европа'},
                    {locale: 'sl-SI', translation: 'Evropa'},
                    {locale: 'sv-SE', translation: 'Europa'},
                    {locale: 'uk-UA', translation: 'Європа'},
                    {locale: 'sk-SK', translation: 'Európa'},
                    //
                    {locale: 'zh-CN', translation: '欧洲'},
                    {locale: 'ja-JP', translation: 'ヨーロッパ'},
                    {locale: 'id-ID', translation: 'Eropa'},
                    {locale: 'hi-IN', translation: 'यूरोप'}
                ],
                as_title: [
                    {locale: 'en-US', translation: 'Asia'},
                    {locale: 'es-US', translation: 'Asia'},
                    {locale: 'en-CA', translation: 'Asia'},
                    {locale: 'fr-CA', translation: 'Asie'},
                    //
                    {locale: 'es-AR', translation: 'Asia'},
                    {locale: 'pt-BR', translation: 'Ásia'},
                    //
                    {locale: 'en-GB', translation: 'Asia'},
                    {locale: 'es-ES', translation: 'Asia'},
                    {locale: 'fr-FR', translation: 'Asie'},
                    {locale: 'de-DE', translation: 'Asien'},
                    {locale: 'pt-PT', translation: 'Asie'},
                    {locale: 'it-IT', translation: 'Asia'},
                    {locale: 'da-DK', translation: 'Asien'},
                    {locale: 'nl-NL', translation: 'Azië'},
                    {locale: 'bg-BG', translation: 'Азия'},
                    {locale: 'cs-CZ', translation: 'Asie'},
                    {locale: 'et-EE', translation: 'Aasias'},
                    {locale: 'fi-FI', translation: 'Aasia'},
                    {locale: 'el-GR', translation: 'Ασία'},
                    {locale: 'hu-HU', translation: 'Ázsia'},
                    {locale: 'lv-LV', translation: 'Āzija'},
                    {locale: 'lt-LT', translation: 'Azija'},
                    {locale: 'no-NO', translation: 'Asia'},
                    {locale: 'pl-PL', translation: 'Azja'},
                    {locale: 'ro-RO', translation: 'Asia'},
                    {locale: 'ru-RU', translation: 'Азия'},
                    {locale: 'sr-RS', translation: 'Азија'},
                    {locale: 'sl-SI', translation: 'Azija'},
                    {locale: 'sv-SE', translation: 'Asien'},
                    {locale: 'uk-UA', translation: 'Азія'},
                    {locale: 'sk-SK', translation: 'Ázie'},
                    //
                    {locale: 'zh-CN', translation: '亚洲'},
                    {locale: 'ja-JP', translation: 'アジア'},
                    {locale: 'id-ID', translation: 'Asia'},
                    {locale: 'hi-IN', translation: 'एशिया'}
                ],
                continue_label: [
                    {locale: 'en-US', translation: 'Continue'},
                    {locale: 'es-US', translation: 'Continuar'},
                    {locale: 'en-CA', translation: 'Continue'},
                    {locale: 'fr-CA', translation: 'Continuer'},
                    //
                    {locale: 'es-AR', translation: 'Continuar'},
                    {locale: 'pt-BR', translation: 'Continuar'},
                    //
                    {locale: 'en-GB', translation: 'Continue'},
                    {locale: 'es-ES', translation: 'Continuar'},
                    {locale: 'fr-FR', translation: 'Continuer'},
                    {locale: 'de-DE', translation: 'Weiter'},
                    {locale: 'pt-PT', translation: 'Continuar'},
                    {locale: 'it-IT', translation: 'Continua'},
                    {locale: 'da-DK', translation: 'Fortsæt'},
                    {locale: 'nl-NL', translation: 'Verder'},
                    {locale: 'bg-BG', translation: 'Продължи'},
                    {locale: 'cs-CZ', translation: 'Pokračovat'},
                    {locale: 'et-EE', translation: 'Jätka'},
                    {locale: 'fi-FI', translation: 'Jatka'},
                    {locale: 'el-GR', translation: 'Συνέχεια'},
                    {locale: 'hu-HU', translation: 'Tovább'},
                    {locale: 'lv-LV', translation: 'Tęsti'},
                    {locale: 'lt-LT', translation: 'Tęsti'},
                    {locale: 'no-NO', translation: 'Fortsett'},
                    {locale: 'pl-PL', translation: 'Dalej'},
                    {locale: 'ro-RO', translation: 'Continuare'},
                    {locale: 'ru-RU', translation: 'Продолжить'},
                    {locale: 'sr-RS', translation: 'Nastavi'},
                    {locale: 'sl-SI', translation: 'Nadaljuj'},
                    {locale: 'sv-SE', translation: 'Fortsätt'},
                    {locale: 'uk-UA', translation: 'Продовжуй'},
                    {locale: 'sk-SK', translation: 'Pokračovať'},
                    //
                    {locale: 'zh-CN', translation: '继续'},
                    {locale: 'ja-JP', translation: '続ける'},
                    {locale: 'id-ID', translation: 'Lanjutkan'},
                    {locale: 'hi-IN', translation: 'जारी रखें'}
                ]
            },
            opacity: {
                na: '.25',
                sa: '.25',
                eu: '.25',
                as: '.25',
            }
        }
    },
    methods: {
        continueChooseLang() {
            this.pickLang(this.selectedLang);
        },
        selectMap(event) {
            this.langOptions.length = 0;
            this.selectedLang = null;
            if(event.id == 1) {
                this.na.langs.forEach(element => {
                    this.langOptions.push({
                        value: element,
                        text: element.label.toUpperCase()
                    })
                });
                this.selectedLang = this.langOptions[0].value;
                this.mapOptions[0].text = this.translations.na_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();

            }
            else if(event.id == 2) {
                this.sa.langs.forEach(element => {
                    this.langOptions.push({
                        value: element,
                        text: element.label.toUpperCase()
                    })
                });
                this.selectedLang = this.langOptions[0].value;
                this.mapOptions[1].text = this.translations.sa_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            }
            else if(event.id == 3) {
                this.eu.langs.forEach(element => {
                    this.langOptions.push({
                        value: element,
                        text: element.label.toUpperCase()
                    })
                });
                this.selectedLang = this.langOptions[0].value;
                this.mapOptions[2].text = this.translations.eu_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            }
            else if(event.id == 4) {
                this.as.langs.forEach(element => {
                    this.langOptions.push({
                        value: element,
                        text: element.label.toUpperCase()
                    })
                });
                this.selectedLang = this.langOptions[0].value;
                this.mapOptions[3].text = this.translations.as_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            }
            else {
                this.na.langs.forEach(element => {
                    this.langOptions.push({
                        value: element,
                        text: element.label.toUpperCase()
                    })
                });
                this.selectedLang = this.langOptions[0].value;
                this.mapOptions[0].text = this.translations.na_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            }
            this.title = this.translations.title.find(el => el.locale === this.selectedLang.locale).translation;
            this.continueLabel = this.translations.continue_label.find(el => el.locale === this.selectedLang.locale).translation;
        },
        selectLang(event) {
            this.mapOptions[0].text = this.translations.na_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            this.mapOptions[1].text = this.translations.sa_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            this.mapOptions[2].text = this.translations.eu_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            this.mapOptions[3].text = this.translations.as_title.find(el => el.locale === this.selectedLang.locale).translation.toUpperCase();
            this.title = this.translations.title.find(el => el.locale === this.selectedLang.locale).translation;
            this.continueLabel = this.translations.continue_label.find(el => el.locale === this.selectedLang.locale).translation;
        },
        close() {
            this.$emit('close');
        },
        mouseLeaveImg(map) {
            map.isHovered = false;
            map.langs.forEach(element => {
                element.isHovered = false;
            });
        },
        mouseOverImg(name) {
            if(name === 'na') {
                this.na.isHovered = true;
                this.na.langs.forEach(element => {
                    element.isHovered = true;
                });
                return;
            }
            if(name === 'sa') {
                this.sa.isHovered = true;
                this.sa.langs.forEach(element => {
                    element.isHovered = true;
                });
                return;
            }
            if(name === 'eu') {
                this.eu.isHovered = true;
                this.eu.langs.forEach(element => {
                    element.isHovered = true;
                });
                return;
            }
            if(name === 'as') {
                this.as.isHovered = true;
                this.as.langs.forEach(element => {
                    element.isHovered = true;
                });
                return;
            }
        },
        mouseOverLang(language) {
            this.title = this.translations.title.find(el => el.locale === language.locale).translation;
            this.na.label = this.translations.na_title.find(el => el.locale === language.locale).translation;
            this.sa.label = this.translations.sa_title.find(el => el.locale === language.locale).translation;
            this.eu.label = this.translations.eu_title.find(el => el.locale === language.locale).translation;
            this.as.label = this.translations.as_title.find(el => el.locale === language.locale).translation;
        },
        pickLang(lang) {
            this.close();
            let host = location.host;
            let langUrl = new URL(lang.url);
            let langHost = langUrl.host;
            let url = location.href;
            url = this.removeParam('lang', url);
            if(host == langHost) {
                const langChooseEvent = new window.CustomEvent('langChooseEvent');
                this.$store.dispatch(API_TRANSLATIONS_ACTION_LOAD, lang.locale)
				.then(() => {
					this.$i18n.setLocaleMessage(lang.locale, this.Message);
					this.$i18n.locale = lang.locale;
					window.localStorage.setItem("USER_LANGUAGE", lang.locale);
                    document.dispatchEvent(langChooseEvent);
                    let removedParamHref = this.removeParam('lang', url);
                    // console.log(removedParamHref);
                    window.location.href = removedParamHref;
				})
                .catch(e => {
                    console.log(e);
                })
            }
            else {
                let newHref = new URL(url + '?lang=' + lang.locale);
                let newUrl = new URL(lang.url.slice(0, lang.url.length - 1) + newHref.pathname + newHref.search);
                window.location.href = newUrl.href;
            }
        },
        removeParam(key, sourceURL) {
            var splitUrl = sourceURL.split('?'),
                rtn = splitUrl[0],
                param,
                params_arr = [],
                queryString = (sourceURL.indexOf("?") !== -1) ? splitUrl[1] : '';
            if (queryString !== '') {
                params_arr = queryString.split('&');
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split('=')[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
                }
                rtn = rtn + '?' + params_arr.join('&');
            }
            return rtn;
        }
    },
    computed: {
			...mapGetters({
				Message: "getTranslationsMessage",
				getTranslationsSelectedLocale: "getTranslationsSelectedLocale",
				getTranslationsLocales: "getTranslationsLocales"
            }) 
			 
		},
}
</script>

<style>
.choose-language-modal .continue-btn {
    height: 100%;
    min-height: 40px;
    
}
@media only screen and (max-width: 380px) {
    .choose-language-modal .form-control, output {
        font-size: 10px !important;
    }
    .choose-language-modal .continue-btn {
        font-size: 12px !important;
    }
}
.choose-language-modal .select-map {
    margin-left: 30%;
    margin-right:30%;
}
@media only screen and (max-width: 767px) {
    .choose-language-modal #el-wrapper, .choose-language-modal #redirect {
        width: 100%;
        max-width: 100% !important;
        height: 100%;
        top: 0 !important;
        padding: 25px 0 0 !important;
    }
}

.choose-language-modal .region-col {
    padding: 0 27px;
}
.choose-language-modal .grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
}
.choose-language-modal .row {
    margin-top: 20px;
}
.choose-language-modal.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}
.choose-language-modal #el-wrapper,
.choose-language-modal #redirect {
    background: #274ba7;
    max-width: 930px;
    border: 12px solid rgba(255,255,255,0.6);
    position: relative;
    box-shadow: 0 2px 5px #000;
    top: 100px;
    padding: 25px 25px 35px;
}
.choose-language-modal .closeButton {
    color: #fff;
    opacity: .75;
    right: 3px;
    top: 0;
    position: absolute;
    /* -ms-opacity: .50; */
    /* opacity: .50; */
    /* color: #000; */
    font-size: 27px;
    text-decoration: none;
    padding: 5px 10px
}
.choose-language-modal .closeButton:hover {
    opacity: 1;
}
.choose-language-modal button {
    /* -webkit-appearance: inherit; */
    /* -webkit-writing-mode: inherit; */
    text-rendering: inherit;
    color: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    text-transform: inherit;
    text-indent: inherit;
    text-shadow: inherit;
    display: inherit;
    text-align: inherit;
    align-items: inherit;
    cursor: pointer;
    background-color: inherit;
    background: transparent;
    margin: 0;
    padding: 0;
    font: inherit;
    border: 0;
    box-sizing: border-box;
}


/* Maps Logos section */
.choose-language-modal .map {
    height: 133px;
    width: 100%;
}
.choose-language-modal #na-map {
    background: url('@p/assets/png/na.png') no-repeat center center;
    transition: opacity 0.2s;
}
.choose-language-modal #sa-map { background: url('@p/assets/png/sa.png') no-repeat center center; transition: opacity 0.2s; }
.choose-language-modal #eu-map { background: url('@p/assets/png/eu.png') no-repeat center center; transition: opacity 0.2s; }
.choose-language-modal #as-map { transition: opacity 0.2s; background: url('@p/assets/png/as.png') no-repeat center center; }

/* End of Map Logos section  */

/* Map Name and Lang section  */
.choose-language-modal .map-name {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    white-space: nowrap;
    text-transform: uppercase;
}
/* .map-lang-wrapper>.span-lang {
    text-align: center;
} */
.choose-language-modal .map-lang {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    color: #fff;    
    width: 100%;
}

.choose-language-modal .map-lang>div {
    margin: 0 10px 5px;
    padding: 5px 12px 0;
}

/* .choose-language-modal .na>.map-lang>div, 
.choose-language-modal .sa>.map-lang>div {
    margin-top: 10px;
    margin-left: 40px;
}
.choose-language-modal .as>.map-lang>div {
    margin-right: 25px;
} */
.choose-language-modal .span-lang {
    transition: color 0.1s text-decoration 0.2s;
}
.choose-language-modal .span-lang:hover {
    color: yellow;
    text-decoration: underline;
    transition-duration: 150ms;
    cursor: pointer;
}
/* End of Map Names section  */
.choose-language-modal .ul-lang {
    text-align: center;
    column-gap: 0;
    column-count: 3;
    list-style: none;
    height: 279px;
}
.choose-language-modal .na {
    width: 25% !important;
    float: left;
} 
.choose-language-modal .sa {
    width: 23% !important;
    float: left;
}
.choose-language-modal .as {
    width: 23% !important;
    float: right;
}
.choose-language-modal .eu {
    width: 25% !important;
    float: left;
}
.choose-language-modal li {
    margin-top: 10px;
}
.choose-language-modal .as>div {
    text-align: center;
}
/* Title section  */
.choose-language-modal label {
    font-size: 22px;
    font-weight: bold;
}
.choose-language-modal .wrapper-title {
    text-align: center;
}
.choose-language-modal #logo {
    margin: 40px 0 0 0;
}
.choose-language-modal #logo img {
    display: none;
}
/* end of title section  */

</style>